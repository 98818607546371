<template>
    <el-select style="width: 100%" size="mini" v-model="data.values" multiple filterable clearable @change="onChange"
      remote
      reserve-keyword
      placeholder="Поиск"
      :remote-method="remoteMethod"
      :loading="loading"
      v-loading="isLoaded">
    <el-option
       v-for="item in options"
      :key="item.id"
      :label="normalizedLabel(item)"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      data: {
        values: []
        // type: null
      },
      options: [],
      state: [],
      loading: false,
      isLoaded: false
    }
  },
  methods: {
    normalizedLabel (label) {
      let result = label.name?.length > 50 ? label.name.substring(0, 50) + '...' : label.name

      return result
    },
    async render () {
      if (this.value) {
        let properties = this.value.split(',')
        properties.shift()
        //        this.data.type = properties.shift()
        console.log(properties, properties.length, properties.length > 0)
        this.data.values = properties.length > 0 ? properties.filter((item) => item !== '').map((item) => parseInt(item)) : []
      }
      if (this.options.length === 0) {
        this.isLoaded = true
        let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${this.attributeId}/data`)
        this.isLoaded = false
        this.state = response.data
        if (response.data?.length > 100) {
          this.options = response.data.slice(0, 50)
        } else {
          this.options = response.data
        }
      }
    },
    onChange () {
      let value = null
      if (this.data.values.length > 0) {
        value = `eqx,${this.data.values.join(',')}`
      }
      this.$emit('input', value)
    },
    remoteMethod (query) {
      if (query !== '') {
        this.options = []
        this.loading = true
        let foundItems = []

        setTimeout(() => {
          this.loading = false
          foundItems = this.state.filter(item => {
            if (!item.name) return false
            return item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          })

          if (foundItems.length > 100) {
            this.options = foundItems.slice(0, 50)
          } else {
            this.options = foundItems
          }
        }, 200)
      } else {
        if (this.state.length > 100) {
          this.options = this.state.slice(0, 50)
        } else {
          this.options = this.state
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
